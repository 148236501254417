<template>
  <div class="app-wrapper wrapper-view">
    <div class="wrapper-title">高频词列表</div>
      <div class="content-head">
        <div style="color:gray">核心词：{{this.k}}</div>
        <div class="head-btn">
          <div class="btn" @click="goBack()">返回</div>
        </div>
      </div>
    <div
      class="source-material-list"
      v-if="tableData.length"
      v-loading="isLoading"
    >
      <div
        class="source-material-item"
        v-for="item in tableData"
        :key="item.id"
      >
        <div class="item-content">
          <div class="li"></div>
          <div class="li-text">{{ item.freqKwd }}</div>
        </div>
        <div class="item-remove">
          <span @click="handleRemove(item.freqKwd)">
            <i class="el-icon-eleme"></i>删除</span
          >
        </div>        
      </div>
    </div>
    <el-empty v-else description="暂无上传"></el-empty>
    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";

const URL = {
  list: "api/operation/material/frequentKwdList",
  remove: "api/operation/material/frequentKwdRemove",
};
export default {
  data() {
    return {
      tableData: [],
      k:this.$route.query?.k,
      u:this.$route.query?.u,
      t:this.$route.query?.t,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          seedKwd:this.k,
          userId:this.u,
          page,
          limit: size,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    goBack() {
      this.$rou;
          this.$router.push({
            name: "SeedKeyWords",
            query: {
              id:this.t,
            },
          });
    },
    handleRemove(freqKwd) {
      this.$confirm("确认删除此数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          HTTP({
            url: URL.remove,
            method: "post",
            data: {
              userId: this.u,
              seedKwd: this.k,
              freqKwd: freqKwd,
            },
          }).then(() => {
            this.$message.success("删除成功");
            this.getList(1);
          });
        })
        .catch(() => {
          this.$message.info("已取消【删除】操作");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  display: flex;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
  font-weight: normal;
  color: #7c7f97;
  line-height: 24px;
  margin-top: 10px;
}
.source-material-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // border-top: 1px solid #e4e8fc;
  border-left: 1px solid #e4e8fc;
  margin-top: 14px;
  .source-material-item {
    width: 25%;
    padding: 40px 20px;
    border-right: 1px solid #e4e8fc;
    border-bottom: 1px solid #e4e8fc;
    border-top: 1px solid #e4e8fc;
    .item-content {
      display: flex;
      align-items: center;
      justify-content: center;
      .li {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #7c7f97;
        margin-right: 10px;
      }
      .li-text {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #7c7f97;
      }
    }
    .item-remove {
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #7c7f97;
      margin-top: 20px;
      & span:hover {
        color: #4e6bef;
        cursor: pointer;
      }
    }
    &:hover {
      background-color: #f6f6fd;
    }
  }
}
.content-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .head-btn {
    display: flex;
    align-items: center;
    .btn {
      width: 128px;
      height: 33px;
      background: #4e6bef;
      margin-right: 12px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>
